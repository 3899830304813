import React, { useEffect, useState, useRef } from "react";
import Progress from "components/Cohort/Dashboard/Progress";
import { useStaticQuery, graphql } from "gatsby";
import Spinner from "react-spinkit";
import { SpinnerContainer } from "components/Admin/styles";
import { navigate } from "gatsby";

import {
  getProjectsCompletedByCohortUser,
  getDemoDayProjectsCompletedByCohortUser,
  getLast5MakerLogPosts,
  fetchUserDataByUid,
  getUserOnboardingData,
  getProjectDates,
  checkUserDataForCohort,
  fetchActiveCohort,
} from "api/db";

import AuthUserContext from "components/Session/AuthUserContext";
import withAuthorization from "components/Session/withAuthorization";
import { auth } from "api/auth";

import Header from "components/Cohort/Dashboard/Header";
import Calendar from "components/Cohort/Dashboard/Calendar";
import ProjectRecommendations from "components/Cohort/Dashboard/ProjectRecommendations";
import FAQ from "components/Cohort/Dashboard/FAQ";
import Resources from "components/Cohort/Dashboard/Resources";

import PostInput from "components/MakerLog/PostInput";
import Posts from "components/MakerLog/Posts";
import { PostInputContainer } from "components/MakerLog/styles";

import {
  MakerLog,
  ReferralBanner,
  CopyArea,
  CopiedMessage,
} from "components/Cohort/Dashboard/styles";

import { Row, Col } from "react-grid-system";
import { motion, AnimatePresence } from "framer-motion";
import { fetchUserData } from "../../api/db";

const dashboard = () => {
  const projects = useStaticQuery(enlightProjects).allTutorial.nodes;
  const [cohortIBuiltThis, setCohortIBuiltThis] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isCohortUser, setIsCohortUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [onboardingData, setOnboardingData] = useState([]);
  const [enlightUser, setEnlightUser] = useState(null);
  const textAreaRef = useRef(null);
  const [copied, setCopied] = useState(false);

  const [projectDates, setProjectDates] = useState(null);
  const [cohort, setCohort] = useState("");
  const [activeCohort, setActiveCohort] = useState("");

  const onSubmissionClick = () => {
    setCohortIBuiltThis([]);
    fetchUserDataByUid(auth.currentUser.uid).once("value", (snapshot) => {
      let userData = snapshot.val();

      let cohort = "";
      if (userData) {
        cohort = checkUserDataForCohort(userData);
        fetchActiveCohort().once("value", (snapshot) => {
          let activeCohort = snapshot.val();
          setActiveCohort(activeCohort);
          if (
            userData.tags &&
            userData.tags != undefined &&
            (userData.tags.includes("team") || userData.tags.includes("mentor"))
          ) {
            cohort = "cohort " + activeCohort;
          }
          if (cohort != "") {
            getProjectsCompletedByCohortUser(auth.currentUser.uid, function(
              snapshot
            ) {
              let data = snapshot.val();
              let key = snapshot.key;
              let keyObject = { key: key };
              if (
                data &&
                data != undefined &&
                data.tags &&
                data.tags.includes(cohort)
              ) {
                data = { ...data, ...keyObject };
                setCohortIBuiltThis((IBuiltThis) => IBuiltThis.concat(data));
              }
            });

            getDemoDayProjectsCompletedByCohortUser(
              auth.currentUser.uid,
              function(snapshot) {
                let data = snapshot.val();
                let key = snapshot.key;
                let keyObject = { key: key };
                data = { ...data, ...keyObject };
                if (data.tags && data.tags != undefined) {
                  if (data.tags.includes(cohort)) {
                    setCohortIBuiltThis((IBuiltThis) =>
                      IBuiltThis.concat(data)
                    );
                  }
                }
              }
            );
          }
        });
      }
    });
  };

  const removeCopyMessage = () => {
    setCopied(false);
  };

  useEffect(() => {
    onSubmissionClick();
    fetchUserDataByUid(auth.currentUser.uid).once("value", (snapshot) => {
      let userData = snapshot.val();

      let cohort = "";
      if (userData) {
        cohort = checkUserDataForCohort(userData);
        fetchActiveCohort().once("value", (snapshot) => {
          let activeCohort = snapshot.val();
          if (
            userData.tags &&
            userData.tags != undefined &&
            (userData.tags.includes("team") || userData.tags.includes("mentor"))
          ) {
            cohort = "cohort " + activeCohort;
          }
          if (cohort != "") {
            setIsCohortUser(true);
            setCohort(cohort);
            setEnlightUser(userData.id);

            getProjectDates(cohort).once("value", (snapshot) => {
              setProjectDates(snapshot.val());
            });
            getLast5MakerLogPosts((snapshot) => {
              let data = snapshot.val();
              let key = snapshot.key;
              fetchUserDataByUid(data.uid).once("value", function(snapshot) {
                let userData = snapshot.val();
                let keyObject = { key: key };
                const object = { ...data, ...userData, ...keyObject };
                setPosts((users) => users.concat(object));
              });
            });
            setIsLoading(false);

            getUserOnboardingData(auth.currentUser.uid).then((snapshot) => {
              let data = snapshot.val();
              setOnboardingData(data);
            });
          } else {
            navigate("/404");
          }
        });
      } else {
        navigate("/404");
      }
    });
  }, []);

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser && isCohortUser ? (
          <>
            <Header cohort={cohort} />
            <Progress
              onSubmissionClick={onSubmissionClick}
              projects={projects}
              cohortIBuiltThis={cohortIBuiltThis}
              username={JSON.parse(sessionStorage.getItem("userData")).id}
              projectDates={projectDates}
            />
            {/* <ReferralBanner>
              <div>
                <h1>Enjoy the cohort?</h1>
                <p>
                  Share a discount with your friends. Get <b>$5</b> for every
                  friend who joins Cohort 4.
                </p>
                <a
                  onClick={() => {
                    textAreaRef.current.select();
                    document.execCommand("copy");
                    setCopied(true);
                    setTimeout(removeCopyMessage, 2000);
                  }}
                >
                  <CopyArea
                    ref={textAreaRef}
                    value={`https://enlight.nyc/cohort?user=${enlightUser}`}
                  ></CopyArea>
                </a>
                <small>
                  You have <b>5</b> referral invites. Offer valid till August
                  13th, 2020.
                </small>
              </div>
            </ReferralBanner> */}
            {/* <AnimatePresence>
              {copied ? (
                <motion.div
                  key="copyMessage"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <CopiedMessage>Copied!</CopiedMessage>
                </motion.div>
              ) : null}
            </AnimatePresence> */}
            <br />
            <br />
            <Row>
              <Col>
                {/* <ProjectRecommendations
                  projects={projects}
                  onboardingData={onboardingData}
                /> */}
                <Resources cohort={cohort} />
                <FAQ />
              </Col>

              <Col lg={4} md={12}>
                <Calendar cohort={cohort} />
                <br />
                <br />
                {posts.length > 4 ? (
                  <MakerLog>
                    <h2>Share your progress</h2>
                    <PostInputContainer>
                      <PostInput />
                    </PostInputContainer>
                    <Posts posts={posts} />
                  </MakerLog>
                ) : (
                  "Loading..."
                )}
              </Col>
            </Row>
          </>
        ) : isLoading ? (
          <SpinnerContainer>
            <Spinner name="ball-scale-multiple" color="#438cee" />
          </SpinnerContainer>
        ) : (
          "404"
        )
      }
    </AuthUserContext.Consumer>
  );
};

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(dashboard);

export const enlightProjects = graphql`
  query enlightProjects {
    allTutorial(filter: { published: { eq: true } }) {
      nodes {
        date
        description
        id
        img
        difficulty
        title
        published
        language
        projectId
      }
    }
  }
`;
