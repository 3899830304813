import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import { Select } from "./styles";

import Modal from "./Modal";

import {
  BackgroundBar,
  ProgressBarContainer,
  Divider,
  TimelineProjectsContainer,
  ProjectDiv,
  Card,
  Title,
  Left,
  Right,
  Img,
  ProjectSection,
  Share,
} from "components/Cohort/Dashboard/styles";

import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";

const ProjectCard = ({ project }) => {
  return (
    <Link to={`/projects/${project.id}`}>
      <Card className="project">
        <Img src={project.img} />
        <Title>{project.title}</Title>
        <Left> {project.language}</Left>
        <Right> {project.difficulty}</Right>
      </Card>
    </Link>
  );
};

const ProgressBarStyles = {
  backgroundColor: "#438cee",
  position: "relative",
  height: "20px",
  width: "1px",
  top: "0",
  left: "0",
  opacity: "1",
  zIndex: "1",
};

const getIndexOfProject = (id, projects) => {
  let i = 0;
  while (projects[i].id != id && i < projects.length) {
    i++;
  }
  if (i == projects.length) {
    return -1;
  }
  return i;
};

const encodeURL = (link) => {
  link = link.replace(/:/g, "%3A");
  link = link.replace(/\//g, "%2F");
  return link;
};

const Progress = ({
  projects,
  cohortIBuiltThis,
  onSubmissionClick,
  username,
  projectDates
}) => {
  const [enlightProjects, setEnlightProjects] = useState(null);
  const [project, setProject] = useState(null);
  const [projectTwo, setProjectTwo] = useState(null);
  const [modalClick, setModalClick] = useState(false);
  const [modalProject, setModalProject] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [firstDone, setFirstDone] = useState(false); // refers to project 1
  const [projectDone, setProjectDone] = useState(false); // refers to project 2
  const [projectTwoDone, setProjectTwoDone] = useState(false); // refers to project 3
  const [capstoneDone, setCapstoneDone] = useState(false);
  const [cohortSubmissions, setCohortSubmissions] = useState(cohortIBuiltThis);

  const [numberClicked, setNumberClicked] = useState(null);
  const [projectDatesList, setProjectDatesList] = useState(projectDates);

  useEffect(() => {
    setEnlightProjects(projects);
    setCohortSubmissions(cohortIBuiltThis);
    setFirstDone(
      projects && cohortIBuiltThis[0] && cohortIBuiltThis[0].postId
        ? getIndexOfProject(cohortIBuiltThis[0].postId, projects)
        : null
    );
    setProject(
      projects && cohortIBuiltThis[1] && cohortIBuiltThis[1].postId
        ? getIndexOfProject(cohortIBuiltThis[1].postId, projects)
        : null
    );
    setProjectTwo(
      projects && cohortIBuiltThis[2] && cohortIBuiltThis[2].postId
        ? getIndexOfProject(cohortIBuiltThis[2].postId, projects)
        : null
    );
    setProjectDone(cohortIBuiltThis[1] && cohortIBuiltThis[1].postId);
    setProjectTwoDone(cohortIBuiltThis[2] && cohortIBuiltThis[2].postId);
    setCapstoneDone(cohortIBuiltThis[3] && !cohortIBuiltThis[3].postId);
    setProjectDatesList(projectDates);
  }, [projects, cohortIBuiltThis, projectDates]);

  function exitModal() {
    setModalClick(false);
    setModalInfo(null);
    setModalProject(null);
    setNumberClicked(null);
  }

  return (
    <>
      <ProgressBarContainer>
        <BackgroundBar>
          <motion.div
            style={ProgressBarStyles}
            animate={{ width: cohortSubmissions.length * 25 + "%" }}
            transition={{ duration: 1 }}
          />
          <Divider left="0%" />
          <Divider left="25%" />
          <Divider left="50%" />
          <Divider left="75%" />
          <Divider left="100%" />
        </BackgroundBar>
      </ProgressBarContainer>

      <Modal
        numberClicked={numberClicked}
        onSubmissionClick={onSubmissionClick}
        modalInfo={modalInfo}
        clicked={modalClick}
        project={modalProject}
        onExit={exitModal}
      />
      <TimelineProjectsContainer>
        <ProjectDiv left="0%">
          <h3>Kickoff</h3>
          <p>{projectDates ? projectDates[0] : null}</p>
        </ProjectDiv>
        <ProjectDiv left="25%">
          <h3>Project 1</h3>
          <p>{projectDates ? projectDates[1] : null}</p>
          <Select name="Projects" value="" disabled>
            <option selected hidden disabled>
              Build a Personal Website
            </option>
          </Select>
          <ProjectSection>
            {enlightProjects && (
              <>
                <ProjectCard
                  project={
                    enlightProjects[
                      getIndexOfProject(
                        "build-a-personal-website",
                        enlightProjects
                      )
                    ]
                  }
                />
                <a
                  onClick={() => {
                    setModalProject(
                      enlightProjects[
                        getIndexOfProject(
                          "build-a-personal-website",
                          enlightProjects
                        )
                      ]
                    );
                    setModalClick(true);
                    cohortIBuiltThis[0] && setModalInfo(cohortSubmissions[0]);
                    setNumberClicked(0);
                  }}
                  className="submit"
                >
                  {firstDone ? "Edit Submission" : "Submit Project"}
                </a>
              </>
            )}

            <Share>
              <a
                target="_blank"
                href={
                  cohortSubmissions[0] && cohortSubmissions[0].demo
                    ? `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        "I just built my very own personal website with @tryEnlight! \n " +
                          cohortSubmissions[0].demo +
                          "\n \n #100DaysOfCode #Enlight #EnlightCohorts"
                      )}`
                    : `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        "I just built my very own personal website with @tryEnlight! \n \n #100DaysOfCode #Enlight #EnlightCohorts"
                      )}`
                }
              >
                <TwitterIcon size={30} round />
              </a>
              <a
                target="_blank"
                href={
                  cohortSubmissions[0] && cohortSubmissions[0].demo
                    ? `https://facebook.com/sharer/sharer.php?u=${
                        cohortSubmissions[0].demo
                      }&quote=${encodeURIComponent(
                        "I just built my very own personal website with Enlight (https://enlight.nyc)!"
                      )}`
                    : `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        "https://enlight.nyc"
                      )}&quote=${encodeURIComponent(
                        "I just built my very own personal website with Enlight (https://enlight.nyc)! \n \n"
                      )}`
                }
              >
                <FacebookIcon size={30} round />
              </a>
              <a
                target="_blank"
                href={
                  cohortSubmissions[0] && cohortSubmissions[0].demo
                    ? `https://www.linkedin.com/sharing/share-offsite/?url=${cohortSubmissions[0].demo}`
                    : `https://www.linkedin.com/sharing/share-offsite/?url=https://enlight.nyc`
                }
              >
                <LinkedinIcon size={30} round />
              </a>
            </Share>
          </ProjectSection>
        </ProjectDiv>

        <ProjectDiv left="50%">
          <h3>Project 2</h3>
          <p>{projectDates ? projectDates[2] : null}</p>
          <Select
            disabled={projectDone}
            onChange={(event) => {
              setProject(event.target.value);
            }}
            name="Projects"
            value={project}
          >
            <option selected hidden disabled>
              Select second project...
            </option>
            {enlightProjects &&
              enlightProjects.map((project, index) => (
                <option key={project.value} value={index}>
                  {project.title} ({project.language})
                </option>
              ))}
          </Select>

          <ProjectSection>
            {project ? (
              <>
                <ProjectCard project={enlightProjects[project]} />
                <a
                  onClick={() => {
                    setModalProject(enlightProjects[project]);
                    setModalClick(true);
                    cohortIBuiltThis[1] && setModalInfo(cohortSubmissions[1]);
                    setNumberClicked(1);
                  }}
                  className="submit"
                >
                  {projectDone ? "Edit Submission" : "Submit Project"}
                </a>
                <ShareComponent
                  project={enlightProjects[project]}
                  cohortSubmissions={cohortSubmissions[1]}
                />
              </>
            ) : (
              <>
                <br />
                <a
                  className="submit"
                  target="_blank"
                  href="https://www.notion.so/7abe7cbf69a44f77bf13d2715e911864?v=e4f7fe1b2876484fa487a5a8fb9811ee"
                >
                  Get Inspired &#x203A;
                </a>
              </>
            )}
          </ProjectSection>
        </ProjectDiv>
        <ProjectDiv left="75%">
          <h3>Project 3</h3>
          <p>{projectDates ? projectDates[3] : null}</p>
          <Select
            disabled={projectTwoDone}
            onChange={(event) => {
              setProjectTwo(event.target.value);
            }}
            name="Projects"
            value={projectTwo}
          >
            <option selected hidden disabled>
              Select third project...
            </option>
            {enlightProjects &&
              enlightProjects.map((project, index) => (
                <option key={project.value} value={index}>
                  {project.title} ({project.language})
                </option>
              ))}
          </Select>
          <ProjectSection>
            {projectTwo ? (
              <>
                <ProjectCard project={enlightProjects[projectTwo]} />
                <a
                  onClick={() => {
                    setModalProject(enlightProjects[projectTwo]);
                    setModalClick(true);
                    cohortIBuiltThis[2] && setModalInfo(cohortSubmissions[2]);
                    setNumberClicked(2);
                  }}
                  className="submit"
                >
                  {projectTwoDone ? "Edit Submission" : "Submit Project"}
                </a>
                <ShareComponent
                  project={enlightProjects[projectTwo]}
                  cohortIBuiltThis={cohortIBuiltThis[2]}
                />
              </>
            ) : (
              <>
                <br />
                <a
                  className="submit"
                  target="_blank"
                  href="https://www.notion.so/7abe7cbf69a44f77bf13d2715e911864?v=e4f7fe1b2876484fa487a5a8fb9811ee"
                >
                  Get Inspired &#x203A;
                </a>
              </>
            )}
          </ProjectSection>
        </ProjectDiv>
        <ProjectDiv left="100%">
          <h3>Demo Day</h3>
          <p>{projectDates ? projectDates[4] : null}</p>
          <ProjectSection>
            <br />
            <a
              id="finishButton"
              onClick={() => {
                setModalProject(null);
                setModalClick(true);
                cohortIBuiltThis[3] && setModalInfo(cohortIBuiltThis[3]);
                setNumberClicked(3);
              }}
              className="submit"
            >
              {capstoneDone ? "Edit Submission" : "Submit Capstone"}
            </a>

            {capstoneDone ? (
              <>
                <br />
                <br />
                <br />
                <a
                  className="submit"
                  target="_blank"
                  href={`https://enlight.nyc/cohort/certificate/${username}`}
                >
                  View Certificate
                </a>
              </>
            ) : null}
          </ProjectSection>
        </ProjectDiv>
      </TimelineProjectsContainer>
    </>
  );
};

const ShareComponent = ({ project, cohortIBuiltThis }) => (
  <Share>
    <a
      target="_blank"
      href={
        cohortIBuiltThis && cohortIBuiltThis.demo
          ? `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              "I just finished " +
                project.title +
                " on @tryEnlight! \n " +
                cohortIBuiltThis.demo +
                "\n \n #100DaysOfCode #Enlight #EnlightCohorts"
            )}`
          : `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              "I just finished " +
                project.title +
                " on @tryEnlight! \n " +
                "https://enlight.nyc/projects/" +
                project.id +
                "\n \n #100DaysOfCode #Enlight #EnlightCohorts"
            )}`
      }
    >
      <TwitterIcon size={30} round />
    </a>
    <a
      target="_blank"
      href={
        cohortIBuiltThis && cohortIBuiltThis.demo
          ? `https://facebook.com/sharer/sharer.php?u=${
              cohortIBuiltThis.demo
            }&quote=${encodeURIComponent(
              "I just finished " + project.title + " on @tryEnlight! \n "
            )}`
          : `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              "https://enlight.nyc/" +
                project.id +
                "&quote= I just finished" +
                project.title +
                " on @tryEnlight!"
            )}`
      }
    >
      <FacebookIcon size={30} round />
    </a>
    <a
      target="_blank"
      href={
        cohortIBuiltThis && cohortIBuiltThis.demo
          ? `https://www.linkedin.com/sharing/share-offsite/?url=${cohortIBuiltThis.demo}`
          : `https://www.linkedin.com/sharing/share-offsite/?url=https://enlight.nyc/projects/${project.id}`
      }
    >
      <LinkedinIcon size={30} round />
    </a>
  </Share>
);

export default Progress;
