import React, { useEffect, useState } from "react";
import styled from "styled-components";

import IBuiltThis from "components/Post/IBuiltThis";
import CustomIBuiltThis from "components/Cohort/Dashboard/CustomIBuiltThis";
import EditIBuiltThis from "./EditIBuiltThis";

const Button = styled.button`
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  background: #fff;
  color: #000;
  border: 4px solid #0394fc;
  margin-right: 8px;
  border-radius: 4px;
  transition: all 300ms ease;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
    background: #0394fc;
    color: #fff;
  }
`;

const ModalDiv = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: white;
  -moz-box-shadow: 0 0 15px #e4e4e4;
  -webkit-box-shadow: 0 0 15px #e4e4e4;
  box-shadow: 0 0 15px #e4e4e4;
  padding: 15px;
  width: 90%;
  height: ${(props) => props.height};
  box-sizing: border-box;
  @media only screen and (max-width: 1100px) {
    overflow: scroll;
    overflow-x: hidden;
  }

  .exit {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;

const Modal = ({ clicked, project, onExit, modalInfo, onSubmissionClick, numberClicked }) => {
  const [showModal, setShowModal] = useState(clicked);
  const [modalProject, setModalProject] = useState(project);
  const [modalInfoState, setModalInfoState] = useState(null);
  const [numberClickedState, setNumberClicked] = useState(numberClicked);

  useEffect(() => {
    setShowModal(clicked);
    setModalProject(project);
    setModalInfoState(modalInfo);
    setNumberClicked(numberClicked);
  }, [clicked, project, modalInfo, numberClicked]);

  const transition = showModal ? "modal" : "modal hide";

  return (
    <div>
      <ModalDiv
        height={modalProject ? "340px" : "420px"}
        className={transition}
      >
        <span className="exit" onClick={() => {
          onExit();
          setModalInfoState(null);
        }}>
          &#10005;
        </span>
        {!modalInfoState ? (
          modalProject ? (
            <IBuiltThis
              title={modalProject.title}
              projectSlug={modalProject.id}
              numericalId={modalProject.projectId}
              isCohort={true}
              submitted={!clicked}
              numberClicked={numberClickedState}
            />
          ) : (
            <CustomIBuiltThis submitted={!clicked} numberClicked={numberClickedState} />
          )
        ) : (
          <EditIBuiltThis modalInfo={modalInfoState} submitted={!clicked} onSubmissionClick={onSubmissionClick} />
        )}
      </ModalDiv>
    </div>
  );
};

export default Modal;
