import React, { useEffect } from "react";
import { HeaderContainer } from "./styles";

import Label from "components/Elements/Label";

export default function Header({ userData, cohort }) {

  useEffect(() => {}, [cohort]);

  let data = JSON.parse(sessionStorage.getItem("userData"));
  return (
    <HeaderContainer>
      <h1>
        Enlight Cohort <span>{cohort != "" ? `${cohort.match(/\d/)[0]}` : ""}</span>
      </h1>

      <div style={{ textAlign: "center" }}>
        <Label>
          Welcome @
          <a href={`/user/${data.id}`}>
            <span className="gradient-text">{data.id}</span>
          </a>
        </Label>
      </div>
    </HeaderContainer>
  );
}
