import React, { Component } from "react";
import styled from "styled-components";

import {
  CustomIBuiltThisSubmission,
  updateCohortSubmissionMetrics
} from "api/db";
import AuthUserContext from "components/Session/AuthUserContext";
import {
  Container,
  Form,
  Input,
  IBTButton,
  Label
} from "components/Cohort/Dashboard/styles";
import { Row, Col } from "react-grid-system";

import { ImageUpload } from "components/MakerLog/styles";
import { storage } from "api/firebase";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

const CustomIBuiltThisComponent = ({ submitted, numberClicked }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <CustomIBuiltThis
          numberClicked={numberClicked ? numberClicked : null}
          submitted={submitted ? submitted : false}
        />
      ) : null
    }
  </AuthUserContext.Consumer>
);

class CustomIBuiltThis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      github: "",
      demo: "",
      description: "",
      image: "",
      submitted: props.submitted,
      receivedURL: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.submitted != this.props.submitted) {
      this.setState({
        submitted: this.props.submitted
      });
    }
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Container>
            {this.state.submitted ? (
              <Form>
                <h3>
                  Thank you. You just created something special. Looking forward
                  to more!
                </h3>
              </Form>
            ) : (
              <Form>
                <h3>Finish your project? Submit it!</h3>
                <Row>
                  <Col md={6} offset={{ lg: 3 }}>
                    <Label>Title</Label>
                    <Input
                      value={this.state.title}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("title", event.target.value)
                        )
                      }
                      type="text"
                      placeholder="Title of your project"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>GitHub Link</Label>
                    <Input
                      value={this.state.github}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("github", event.target.value)
                        )
                      }
                      type="text"
                      placeholder="https://github.com/"
                    />
                  </Col>

                  <Col md={6}>
                    <Label>
                      Project Demo Link — Repl.it, Google Colab, or Netlify
                    </Label>
                    <Input
                      value={this.state.demo}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("demo", event.target.value)
                        )
                      }
                      type="text"
                      placeholder="https://"
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Project Description</Label>
                    <Input
                      value={this.state.description}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName(
                            "description",
                            event.target.value
                          )
                        )
                      }
                      type="text"
                      placeholder="Type a short description..."
                    />
                  </Col>

                  <Col md={6}>
                    <Label>Project Image</Label>

                    <ImageUpload
                      accept="image/*"
                      onChange={e => {
                        const image = e.target.files[0];
                        let size = image.size / 1024 / 1024; // in MB
                        if (size > 10) {
                          alert("File size exceeds 10 MB");
                        } else {
                          const upload = storage
                            .ref(`/customProjectSubmissions/${image.name}`)
                            .put(image);
                          this.setState({
                            receivedURL: false
                          });

                          upload.on(
                            "state_changed",
                            snapshot => {
                              // console.log(snapshot);
                            },
                            err => {
                              // console.log(err);
                            },
                            () => {
                              storage
                                .ref("customProjectSubmissions")
                                .child(image.name)
                                .getDownloadURL()
                                .then(url => {
                                  this.setState({
                                    receivedURL: true,
                                    image: url
                                  });
                                });
                            }
                          );
                        }
                      }}
                      type="file"
                    />
                  </Col>
                </Row>

                <br />
                <IBTButton
                  onClick={() => {
                    let githubLink = this.state.github;
                    let demoLink = this.state.demo;
                    if (
                      !githubLink.match(/^[a-zA-Z]+:\/\//) &&
                      githubLink != ""
                    ) {
                      githubLink = "https://" + githubLink;
                    }
                    if (!demoLink.match(/^[a-zA-Z]+:\/\//) && demoLink != "") {
                      demoLink = "https://" + demoLink;
                    }
                    CustomIBuiltThisSubmission(
                      githubLink,
                      demoLink,
                      authUser.uid,
                      this.state.title,
                      this.state.description,
                      this.state.image
                    );
                    this.setState({ submitted: true });
                    this.props.numberClicked &&
                      updateCohortSubmissionMetrics(
                        this.props.numberClicked,
                        "completion"
                      );
                  }}
                >
                  {this.state.receivedURL ? "Submit" : "Uploading..."}
                </IBTButton>
              </Form>
            )}
          </Container>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default CustomIBuiltThisComponent;
