import React, { Component } from "react";
import styled from "styled-components";

import {
  UpdateIBuiltThisSubmission,
  UpdateCustomIBuiltThisSubmission
} from "api/db";
import AuthUserContext from "components/Session/AuthUserContext";
import {
  Container,
  Form,
  Input,
  IBTButton,
  Label
} from "components/Cohort/Dashboard/styles";
import { Row, Col } from "react-grid-system";

import { ImageUpload } from "components/MakerLog/styles";
import { storage } from "api/firebase";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

const EditIBuiltThisComponent = ({
  submitted,
  modalInfo,
  onSubmissionClick
}) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <EditIBuiltThis
          modalInfo={modalInfo}
          submitted={submitted ? submitted : false}
          onSubmissionClick={onSubmissionClick}
        />
      ) : null
    }
  </AuthUserContext.Consumer>
);

class EditIBuiltThis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.modalInfo ? props.modalInfo.title : "",
      github: props.modalInfo ? props.modalInfo.github : "",
      demo: props.modalInfo ? props.modalInfo.demo : "",
      description: props.modalInfo ? props.modalInfo.description : "",
      image: props.modalInfo ? props.modalInfo.img : "",
      submitted: props.submitted,
      receivedURL: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.submitted != this.props.submitted) {
      this.setState({
        submitted: this.props.submitted
      });
    }
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Container>
            {this.state.submitted ? (
              <Form>
                <h3>Thank you. You just updated your submission!</h3>
              </Form>
            ) : (
              <Form>
                <h3>Want a change? Edit it!</h3>
                {!this.props.modalInfo.postId ? (
                  <Row>
                    <Col md={6} offset={{ lg: 3 }}>
                      <Label>Title</Label>
                      <Input
                        value={this.state.title}
                        onChange={event =>
                          this.setState(
                            updateByPropertyName("title", event.target.value)
                          )
                        }
                        type="text"
                        placeholder="Title of your project"
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col md={6}>
                    <Label>GitHub Link</Label>
                    <Input
                      value={this.state.github}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("github", event.target.value)
                        )
                      }
                      type="text"
                      placeholder="https://github.com/"
                    />
                  </Col>

                  <Col md={6}>
                    <Label>
                      Project Demo Link — Repl.it, Google Colab, or Netlify
                    </Label>
                    <Input
                      value={this.state.demo}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("demo", event.target.value)
                        )
                      }
                      type="text"
                      placeholder="https://"
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Project Description</Label>
                    <Input
                      value={this.state.description}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName(
                            "description",
                            event.target.value
                          )
                        )
                      }
                      type="text"
                      placeholder="Type a short description..."
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Project Image</Label>

                    <ImageUpload
                      accept="image/*"
                      onChange={e => {
                        const image = e.target.files[0];
                        let size = image.size / 1024 / 1024; // in MB
                        if (size > 10) {
                          alert("File size exceeds 10 MB");
                        } else {
                          const upload = storage
                            .ref(`/projectSubmissions/${image.name}`)
                            .put(image);
                          this.setState({
                            receivedURL: false
                          });

                          upload.on(
                            "state_changed",
                            snapshot => {
                              // console.log(snapshot);
                            },
                            err => {
                              // console.log(err);
                            },
                            () => {
                              storage
                                .ref("projectSubmissions")
                                .child(image.name)
                                .getDownloadURL()
                                .then(url => {
                                  this.setState({
                                    receivedURL: true,
                                    image: url
                                  });
                                });
                            }
                          );
                        }
                      }}
                      type="file"
                    />
                  </Col>

                  {/* <Col md={6}>
                    <Label>Project Image Cover</Label>

                    <Input
                      value={this.state.image}
                      onChange={(event) =>
                        this.setState(
                          updateByPropertyName("image", event.target.value)
                        )
                      }
                      type="text"
                      placeholder="Image link address: https://"
                    />
                  </Col> */}
                </Row>

                <br />
                <IBTButton
                  onClick={() => {
                    let githubLink = this.state.github;
                    let demoLink = this.state.demo;
                    if (
                      !githubLink.match(/^[a-zA-Z]+:\/\//) &&
                      githubLink != ""
                    ) {
                      githubLink = "https://" + githubLink;
                    }
                    if (!demoLink.match(/^[a-zA-Z]+:\/\//) && demoLink != "") {
                      demoLink = "https://" + demoLink;
                    }
                    this.props.modalInfo.postId
                      ? UpdateIBuiltThisSubmission(
                          githubLink,
                          demoLink,
                          this.state.description,
                          this.state.image,
                          this.props.modalInfo.key
                        )
                      : UpdateCustomIBuiltThisSubmission(
                          this.state.title,
                          githubLink,
                          demoLink,
                          this.state.description,
                          this.state.image,
                          this.props.modalInfo.key
                        );

                    this.props.onSubmissionClick();
                    this.setState({ submitted: true });
                  }}
                >
                  {this.state.receivedURL ? "Update" : "Uploading..."}
                </IBTButton>
              </Form>
            )}
          </Container>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default EditIBuiltThisComponent;
