import React from "react";
import { Row, Col } from "react-grid-system";
import { FAQContainer } from "../styles";

export default function FAQ() {
  return (
    <FAQContainer>
      <h2>Tips</h2>
      <Row>
        <Col lg={6} sm={12}>
          <h3>How do I submit a project?</h3>
          <p>
            On the dashboard, click submit under the project number you have
            completed. A popup will appear and it will ask you to type or paste
            in your project source code (through GitHub) and a demo (through
            repl.it, Colab, or a live website). You'll also write a short
            description of your project and give it an image. You can upload
            your image to Enlight directly by choosing your file to upload.
          </p>
        </Col>
        <Col lg={6} sm={12}>
          <h3>
            Where can I find the slides and videos?
          </h3>
          <p>
            Webinars are available for 24 hours after they release. You can find them in the resources section just
            above. The next time all content will be accessible is after Project
            #3 is due; we want you to have all possible resources as you prepare
            your capstone. Webinar slide decks can also be found in the
            resources section of this page in the drive folder.
          </p>
        </Col>
        <Col lg={6} sm={12}>
          <h3>How long does a typical project take?</h3>
          <p>
            If you follow the project tutorial on Enlight, almost all of the
            projects can be completed in about an hour or two. As you decide on
            how to personalize your creation, you might find yourself spending
            about as long customizing your project to your heart's content. Once
            you're done, please do share your project on the #ship channel in
            Discord as well!
          </p>
        </Col>
        <Col lg={6} sm={12}>
          <h3>Where can I get help?</h3>
          <p>
            Discord is probably your best place to start! Utilize the{" "}
            <b>#help</b> and <b>#cohorts</b> channels and ask your question
            while also mentioning at least one example of something you've tried
            (especially with bugs). Check out the{" "}
            <a href="https://forum.enlight.nyc/">Enlight forum</a> where you can
            post (or search for posts) on common questions! 
            You can also tag <i>@Mentors</i> in Discord to get
            right in touch with the mentorship team.
          </p>
        </Col>
      </Row>
    </FAQContainer>
  );
}
