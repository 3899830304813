import React, { useEffect, useState } from "react";
import { getResources, getRecordings } from "api/db";
import {
  ResourcesList,
  Resource,
  ResourcesContainer
} from "components/Cohort/Dashboard/styles";

export default function Resources({ cohort }) {
  const [resources, setResources] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [recordingKeys, setRecordingKeys] = useState([]);
  const [cohortNumber, setCohortNumber] = useState(null);

  useEffect(() => {
    if (cohort != "") {
      let cohortNumber = cohort.match(/(\d+)/);
      setCohortNumber(cohortNumber[0]);

      getResources(cohort).then(snapshot => {
        snapshot.val() && setResources(Object.values(snapshot.val()));
      });

      getRecordings(cohort).then(snapshot => {
        snapshot.val() && setRecordings(Object.entries(snapshot.val()));
      });
    }
  }, [cohort]);

  return (
    <ResourcesContainer>
      <ResourcesList>
        <Resource>
          <h2>Resources</h2>
        </Resource>
        {resources.length &&
        resources.sort((a, b) => (a.order > b.order ? 1 : -1)) ? (
          resources.map((e, index) => {
            let title = e.title;
            let url = e.url;
            return (
              <Resource>
                <a href={url} target="_blank">
                  {title}
                </a>
                {index == 3 || index == 6 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </Resource>
            );
          })
        ) : (
          <Resource>Loading...</Resource>
        )}
      </ResourcesList>
      <ResourcesList>
        <Resource>
          <h2>Webinars</h2>
        </Resource>
        {recordings.length &&
        recordings.sort((a, b) => (new Date(a[1].timestamp) > new Date(b[1].timestamp) ? 1 : -1)) ? (
          recordings.map((e, i) => {
            let webinar = e[1];
            let title = webinar.title;
            let timestamp = webinar.timestamp;
            let thirtySixHours = 1.5 * 24 * 60 * 60 * 1000;
            let oneWeek = 7 * 24 * 60 * 60 * 1000;
            let now = Date.now();
            return (
              <Resource key={webinar.timestamp}>
                {(now < timestamp + thirtySixHours) || webinar.published && webinar.published == true ? (
                  <>
                    <a
                      href={`/cohort/webinar/${cohortNumber}/${e[0]}`}
                      target="_blank"
                    >
                      {title}
                    </a>
                  </>
                ) : (
                  <p>{title}</p>
                )}
              </Resource>
            );
          })
        ) : (
          <Resource>Loading...</Resource>
        )}
      </ResourcesList>
    </ResourcesContainer>
  );
}
