import React, { useEffect, useState } from "react";
import { greaterThanXDays, getCalendarKey, parseCohortTag } from "api/db";
import {
  EventList,
  Event,
  EventContainer
} from "components/Cohort/Dashboard/styles";

const Calendar = ({ cohort }) => {
  const [calendar, setCalendar] = useState([]);
  const [key, setKey] = useState(null);

  useEffect(() => {
    if (cohort != "") {
      let cohortTag = parseCohortTag(cohort);
      if (cohortTag != undefined) {
        getCalendarKey(cohortTag).once("value", function(snapshot) {
          setKey(snapshot.val());
        });

        if (key) {
          let now = new Date().toISOString();
          const api = `https://www.googleapis.com/calendar/v3/calendars/${key}%40group.calendar.google.com/events?maxResults=5&orderBy=startTime&singleEvents=true&timeMin=${now}&key=${process.env.GCP_API_KEY}`;
          fetch(api, { method: "GET" })
            .then(snapshot => snapshot.json())
            .then(data => setCalendar(data.items));
        }
      }
    }
  }, [cohort, key]);

  const setDate = datetime => {
    var date = new Date(datetime);
    var now = new Date();
    var tomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    let dateString = "";
    let happening = false;
    let hours = parseInt((date.getTime() - Date.now()) / (1000 * 60 * 60));
    let minutes = parseInt((date.getTime() - Date.now()) / (1000 * 60));
    let remainderMin = parseInt((date.getTime() - Date.now()) / (1000 * 60)) % 60;

    if (greaterThanXDays(Date.now(), date.getTime(), 1)) {
      if (
        date.getFullYear() == tomorrow.getFullYear() &&
        date.getMonth() == tomorrow.getMonth() &&
        date.getDate() == tomorrow.getDate()
      ) {
        dateString =
          "tomorrow" +
          ", " +
          date.toLocaleString("en-US", { hour: "numeric", minute: 'numeric', hour12: true });
      } else {
        dateString =
          date.getMonth() +
          1 +
          "/" +
          date.getDate() +
          ", " +
          date.toLocaleString("en-US", { hour: "numeric", minute: 'numeric', hour12: true });
      }
    } else if (minutes >= 5 && minutes < 60) {
      dateString =
        minutes + " minutes";
    } else if (
      hours == 1
    ) {
      // one hour
      dateString =
        hours + " hour " + remainderMin + " min";
    } else if (
      hours >= 1
    ) {
      // greater than 1 hour
      dateString =
        hours +
        " hours " + remainderMin + " min, " +
        date.toLocaleString("en-US", { hour: "numeric", minute: 'numeric', hour12: true });
    } else {
      dateString = "live";
      happening = true;
    }
    return [dateString, happening];
  };

  return (
    <EventContainer>
      <EventList>
        <div>
          <h2>Upcoming events</h2>
          {calendar && calendar.length ? (
            calendar.map(event => {
              const start = event.start.dateTime || event.start.date;
              const link = event.location;
              const when = setDate(start)[0];
              const live = setDate(start)[1];
              return (
                <Event key={event.id}>
                  {live ? (
                    <>
                      <span className="live">{when}</span>

                      <section>
                        &nbsp;{" "}
                        <a target="_blank" href={link}>
                          <b>{event.summary}</b>
                        </a>
                        <p>
                          {event.description &&
                            (event.description.length > 100
                              ? event.description.slice(0, 100) + "..."
                              : event.description)}
                        </p>
                      </section>
                    </>
                  ) : (
                    <>
                      <span>{when}</span>
                      <section>
                        &nbsp; <b>{event.summary}</b>
                        <p>
                          {event.description &&
                            (event.description.length > 100
                              ? event.description.slice(0, 100) + "..."
                              : event.description)}
                        </p>
                      </section>
                    </>
                  )}
                </Event>
              );
            })
          ) : (
            <Event>No Upcoming Events</Event>
          )}
        </div>
      </EventList>
    </EventContainer>
  );
};

export default Calendar;
