import styled from 'styled-components'


export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const DashboardContainer = styled.div`
padding: 32px;

table {
  margin: 32px 0px;
  width: 100%; 
  border-collapse: collapse; 
}

h1 {
  text-align: center;
  margin: 16px 8px;
  color: #333;
}

tr:nth-of-type(odd) { 
  background: #f4f4f4; 
}
th { 
  background: #f4f4f4; 
  color: #333; 
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  border: 1px solid #C0C0C0; 
  text-align: center; 
}

.copyDiv {
  display: flex;
  flex-flow: row;
  margin-bottom: 30px;
}

p {
  text-align: center;
  margin-bottom: 32px;
}
`;

export const Card = styled.div`
text-align: center;
font-size: 16px;
background: #438cee;
color: #fff;
padding: 48px;

span {
  display: block;
  font-weight: bold;
  font-size: 64px;
}

.inline span {
  display: inline;
}
`;

export const Buttons = styled.section`
  display: flex;

  a {
    flex: 1;
    list-style-type: none;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    background: #438cee;
    color: #fff;
    padding: 24px;
    margin: 4px;
  }
  a:hover {
    opacity: 0.6;
  }
`;
